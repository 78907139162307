import { ActionTree, Module, MutationTree } from "vuex";
import { UserState, RootState } from "../type";
import { GetterTree } from "vuex";

// State
const state: UserState = {
    userInfo: {},
}


// Getters
const getters: GetterTree<UserState, RootState> = {
    
    // 获取Token
    token: state => state.userInfo?.token || null,
}


// Mutations
const mutations: MutationTree<UserState> = {
    // 设置用户信息
    setUserInfo: (state, payload): void => {
        state.userInfo = payload;
    },

    // 清除用户信息
    clearUserInfo: (state): void => {
        state.userInfo = {};
    }
}


// Actions
const actions: ActionTree<UserState, RootState> = {


}


const user: Module<UserState, RootState> = {
    state,
    mutations,
    actions,
    getters
}

export default user
