import Main from "@/layout/main.vue";
const routes = [
  {
    path: "/designer",
    name: "designer",
    meta: { title: "设计师" },
    redirect: "/designer/designer_list",
    component: Main,
    children: [
      {
        path: "/designer/designer_list",
        name: "designer_list",
        meta: {
          title: "设计师列表",
          parentPath: "/designer",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/designer/designer_list.vue"),
      },
      {
        path: "/designer/designer_details",
        name: "designer_details",
        meta: {
          hidden: true,
          title: "设计师详情",
          parentPath: "/designer",
          prevPath: "/designer/designer_list",
        },
        component: () => import("@/views/designer/designer_details.vue"),
      },
      {
        path: "/designer/apply_list",
        name: "designer_apply_list",
        meta: {
          title: "设计师申请列表",
          parentPath: "/designer",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/designer/apply_list.vue"),
      },
      {
        path: "/designer/image_list",
        name: "designer_image_list",
        meta: {
          title: "图片列表",
          parentPath: "/designer",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/designer/image_list.vue"),
      },
      {
        path: "/designer/order_list",
        name: "designer_order_list",
        meta: {
          title: "订单列表",
          parentPath: "/designer",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/designer/order_list.vue"),
      },
      {
        path: "/designer/withdrawal_list",
        name: "designer_withdrawal_list",
        meta: {
          title: "提现列表",
          parentPath: "/designer",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/designer/withdrawal_list.vue"),
      },
      {
        path: "/designer/brand_list",
        name: "designer_brand_list",
        meta: {
          title: "品牌列表",
          parentPath: "/designer",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/designer/brand_list.vue"),
      },
      {
        path: "/designer/brand_edit",
        name: "designer_brand_edit",
        meta: {
          hidden: true,
          title: "品牌编辑",
          parentPath: "/designer",
          prevPath: "/designer/brand_list",
        },
        component: () => import("@/views/designer/brand_edit.vue"),
      },
      {
        path: "/designer/brand_image",
        name: "designer_brand_image",
        meta: {
          title: "品牌图片",
          parentPath: "/designer",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/designer/brand_image.vue"),
      },{
        path: "/designer/brand_image_edit",
        name: "designer_brand_image_edit",
        meta: {
          hidden: true,
          title: "品牌图片编辑",
          parentPath: "/designer",
          prevPath: "/designer/brand_image",
        },
        component: () => import("@/views/designer/brand_image_edit.vue"),
      },
    ],
  },
];

export default routes;
